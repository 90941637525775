<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('announce.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('announce.head_list') }} </v-card-title>
        <v-card-actions class="pb-4 d-flex align-end">
          <v-spacer />
          <v-btn color="primary" class="px-5" @click="$router.push('/announce/announce_form_create')">
            <v-icon class="px-3">mdi-plus</v-icon>
            <span class="pr-3">{{ $t('announce.create') }}</span>
          </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">ค้นหา:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
          </div>
        </v-card-actions> -->
        <v-card-actions class="pt-0">
          <v-data-table
            mobile-breakpoint="0"
            style="width: 100%"
            :headers="headers"
            :items="itemsData"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.enable`]="{ item }">
              <span v-if="item.enable" class="success--text">{{ $t('open') }}</span>
              <span v-else class="error--text">{{ $t('close') }}</span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('announce.manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item @click="$router.push(`/news/news_form_update/${item.id}`)">
                    <v-list-item-title>
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">แก้ไข</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider /> -->
                  <v-list-item @click="deleteAnnounce(item)">
                    <v-list-item-title>
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t('delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date: null,
      currentMemberDetail: {},
      search: '',
      loading: false,
      headers: [
        {
          text: this.$t('announce.header1'),
          value: 'message',
          width: '200px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: 'เรียงลำดับ',
        //   value: 'enable',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },
        // {
        //   text: 'สถานะ',
        //   value: 'enable',
        //   width: '95px',
        //   align: 'center',
        //   sortable: false,
        // },
        // {
        //   text: 'วันที่',
        //   value: 'created_at',
        //   width: '95px',
        //   align: 'center',
        //   sortable: false,
        // },
        {
          text: this.$t('announce.header2'),
          value: 'actions',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    // await this.getAnnounce(this.pagination.page, this.pagination.rowsPerPage)
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          await this.getAnnounce()
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
            (data = {
              ip: res.data.ip,
              name: 'ประกาศข้อความ',
              url: window.location.href,
              detail: 'ระบบจัดการประกาศข้อความ',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getAnnounce(page, row) {
      this.itemsData = []
      try {
        const result = await this.$store.dispatch('getAnnounce')
        this.itemsData = result.data
        this.pagination.totalItems = result.count
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async deleteAnnounce(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('alert_list.list11')} ${item.message}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            const deletei = await this.$store.dispatch('deleteAnnounce', item.id)

            this.$swal.fire(this.$t('alert_list.list12'), '', 'success')
            await this.getAnnounce(this.pagination.page, this.pagination.itemsPerPage)
            this.loading = false
          }
        })
    },

    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>
